import React, { createContext, useState, useContext } from 'react';
import SnackbarAlert from '../components/SnackbarAlert';

export const SnackbarContext = createContext();

export default function SnackbarContextProvider({ children }) {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleOpenSnackbar = (severity, message) => {
        setOpenSnackbar(true);
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);

        setTimeout(() => {
            setOpenSnackbar(false);
        }, 3000);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const value = {
        openSnackbar,
        snackbarSeverity,
        snackbarMessage,
        handleOpenSnackbar,
        handleCloseSnackbar
    };

    return (
        <SnackbarContext.Provider value={value}>
            {children}
            <SnackbarAlert
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                severity={snackbarSeverity}
                message={snackbarMessage} 
            />
        </SnackbarContext.Provider>
    );
}

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarContextProvider');
    }
    return context;
};