import React, { useEffect, useState } from 'react';
import TaskDataService from '../../services/Task.services';
import TaskMenu from './TaskMenu';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Divider, Typography, TableSortLabel, Tooltip } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TaskIcon from '@mui/icons-material/Task';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useLoading } from '../../contexts/loading-context';

const columns = [
    { id: 'menu', align: 'left', label: 'Actions', minWidth: 50, sortable: true },
    { id: 'assignee', align: 'left', label: 'Assignee', minWidth: 175, sortable: true },
    { id: 'title', align: 'left', label: 'Title', minWidth: 300, sortable: true },
    { id: 'due', align: 'left', label: 'Due', minWidth: 100, sortable: true },
    { id: 'subtasks', align: 'right', label: '', minWidth: 50, sortable: false },
];

export default function ProjectTaskTable(props) {
    const { token, project, quote, user, taskList, menuSelection, tasks, setTasks, handleOpenSnackbar } = props;
    const { openTaskForm, setOpenTaskForm } = props;
    const { editing, setEditing, setEditTask } = props;
    const { updateTask, updateTasksFromFiltered, updateSubTasksFromFiltered } = props;
    const [subtask, setSubtask] = React.useState({});
    const [subtaskEditing, setSubtaskEditing] = React.useState(false)
    const [titleName, setTitleName] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('due');
    const [sortOrder, setSortOrder] = useState('asc');
    const [openSubtask, setOpenSubtask] = React.useState(false);
    const [openSubtasks, setOpenSubtasks] = React.useState({})
    const { setIsLoading } = useLoading();

    React.useEffect(() => {
        if (!project || !quote) {
            setTitleName('');
        }
    }, [project, quote]);

    useEffect(() => {
        // Clear tasks when project or quote changes
        if (project || quote) {
            setTasks([]); // Reset tasks immediately
        }
    }, [project, quote, setTasks]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const toggleSubtasks = (taskId) => {
        setOpenSubtasks((prevState) => ({
            ...prevState,
            [taskId]: !prevState[taskId],
        }));
    };

    const handleSort = (columnId) => {
        if (sortColumn === columnId) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(columnId);
            setSortOrder('asc');
        }
    };

    useEffect(() => {
        if (taskList && taskList.title) {
            setTitleName(taskList.title);
        }
    }, [taskList]);

    const toggleSubtaskCompletion = (taskId, subtaskId) => {
        setIsLoading(true);
        TaskDataService.completeSubtask(subtaskId, token)
            .then((response) => {
                handleOpenSnackbar('success', 'Subtask has been updated');

                setTasks((prevTasks) =>
                    prevTasks.map((task) =>
                        task.id === taskId
                            ? {
                                ...task,
                                subtasks: task.subtasks.map((subtask) =>
                                    subtask.id === subtaskId
                                        ? { ...subtask, is_complete: !subtask.is_complete }
                                        : subtask
                                ),
                            }
                            : task
                    )
                );
            }
            )
            .catch((e) => {
                console.log(e);
                handleOpenSnackbar('error', 'Something Went Wrong!! Please try again.');
            }
            )
            .finally(() => {
                setIsLoading(false);
            }
            );
    };

    const handleSubtaskDeleted = (taskId, subtaskId) => {
        setTasks((prevTasks) =>
            prevTasks.map((task) =>
                task.id === taskId
                    ? {
                        ...task,
                        subtasks: task.subtasks.filter((subtask) => subtask.id !== subtaskId),
                    }
                    : task
            )
        );
        updateSubTasksFromFiltered(taskId, subtaskId);
        setOpenSubtasks({}); // Close all subtasks
    };

    const handleOpenEditSubtask = (subtask) => {
        setSubtaskEditing(true);
        setSubtask(subtask);
        setOpenSubtask(true);
    };

    // Sorting rows based on the selected column and order
    const sortedTasks = tasks
        ? [...tasks].sort((a, b) => {
            if (sortColumn === 'due') {
                return sortOrder === 'asc'
                    ? new Date(a.due) - new Date(b.due)
                    : new Date(b.due) - new Date(a.due);
            } else if (sortColumn === 'assignee') {
                const aAssignee = (a.assignee && a.assignee.first_name) || '';
                const bAssignee = (b.assignee && b.assignee.first_name) || '';
                return sortOrder === 'asc'
                    ? aAssignee.localeCompare(bAssignee)
                    : bAssignee.localeCompare(aAssignee);
            } else if (sortColumn === 'title') {
                return sortOrder === 'asc'
                    ? a.title.localeCompare(b.title)
                    : b.title.localeCompare(a.title);
            }
            return 0; // Default no sorting
        })
        : [];

    if (titleName && (project || quote)) {
        return (
            <div style={{ width: '100%' }}>
                <Stack spacing={0} sx={{ mb: 2 }}>
                    {project && project.number && project.name && (
                        <Typography sx={{ textAlign: 'center', mt: 2 }} variant="h6" gutterBottom>
                            {`${project.number} ${project.name}`}
                        </Typography>
                    )}
                    {quote && quote.number && quote.name && (
                        <Typography sx={{ textAlign: 'center', mt: 2 }} variant="h6" gutterBottom>
                            {`${quote.number} ${quote.name}`}
                        </Typography>
                    )}
                    <Typography sx={{ textAlign: 'center' }} variant="body1" gutterBottom>
                        {titleName || 'Task List'}
                    </Typography>
                </Stack>
                <Divider sx={{ mb: 0 }} />
                {tasks && tasks.length > 0 ? (
                    <div>
                        <TableContainer component={Box} sx={{ width: '100%' }}>
                            <Table size="small" stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                sx={{
                                                    minWidth: column.minWidth,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {column.id === 'menu' ? (
                                                    column.label
                                                ) : (
                                                    column.sortable &&
                                                    <TableSortLabel
                                                        active={sortColumn === column.id}
                                                        direction={sortColumn === column.id ? sortOrder : 'asc'}
                                                        onClick={() => handleSort(column.id)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedTasks
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((task, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow
                                                    // hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                // onClick={() => task.subtasks.length > 0 && toggleSubtasks(task.id)}
                                                >
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            sx={{
                                                                textDecoration: task.is_complete
                                                                    ? 'line-through'
                                                                    : 'none',
                                                                color: task.is_complete
                                                                    ? 'gray'
                                                                    : 'inherit',
                                                            }}
                                                        >
                                                            {column.id === 'subtasks' ? (

                                                                <Badge
                                                                    badgeContent={
                                                                        task.subtasks.length === 0
                                                                            ? 0
                                                                            : task.subtasks.filter(subtask => !subtask.is_complete).length
                                                                    }
                                                                    color="primary"
                                                                    overlap="circular"
                                                                >
                                                                    <Tooltip title="Subtasks">
                                                                        <span>
                                                                            <IconButton
                                                                                disabled={task.subtasks.length === 0}
                                                                                onClick={() => toggleSubtasks(task.id)}
                                                                            >
                                                                                {openSubtasks[task.id] ? <ExpandLess /> : <ExpandMore />}
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </Badge>
                                                            ) : column.id === 'menu' ? (
                                                                <TaskMenu
                                                                    key={task.id}
                                                                    task={task}
                                                                    tasks={tasks}
                                                                    setTasks={setTasks}
                                                                    token={token}
                                                                    user={user}
                                                                    handleOpenSnackbar={handleOpenSnackbar}
                                                                    openTaskForm={openTaskForm}
                                                                    setOpenTaskForm={setOpenTaskForm}
                                                                    editing={editing}
                                                                    setEditing={setEditing}
                                                                    setEditTask={setEditTask}
                                                                    updateTask={updateTask}
                                                                    openSubtask={openSubtask}
                                                                    setOpenSubtask={setOpenSubtask}
                                                                    subtask={subtask}
                                                                    setSubtask={setSubtask}
                                                                    subtaskEditing={subtaskEditing}
                                                                    setSubtaskEditing={setSubtaskEditing}
                                                                    handleSubtaskDeleted={handleSubtaskDeleted}
                                                                    updateTasksFromFiltered={updateTasksFromFiltered}
                                                                />
                                                            ) : column.id === 'due' ? (
                                                                moment(task[column.id]).format('MM/DD/YYYY')
                                                            ) : column.id === 'assignee' ? (
                                                                `${(task.assignee &&
                                                                    task.assignee.first_name) || ''} ${(task.assignee &&
                                                                        task.assignee.last_name) || ''
                                                                }`
                                                            ) : (
                                                                task[column.id]
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
                                                        colSpan={columns.length}
                                                    >
                                                        <Collapse
                                                            in={
                                                                openSubtasks[
                                                                task.id
                                                                ]
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                        >
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left">Actions</TableCell>
                                                                        <TableCell align="left">Title</TableCell>
                                                                        <TableCell align="right">Complete</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {task.subtasks &&
                                                                        task.subtasks.map(
                                                                            (
                                                                                subtask
                                                                            ) => (
                                                                                <TableRow
                                                                                    key={
                                                                                        subtask.id
                                                                                    }
                                                                                >
                                                                                    <TableCell align="left" sx={{ width: '40px' }}>
                                                                                        <Tooltip title="Edit Subtask">
                                                                                            <IconButton
                                                                                                onClick={() => handleOpenEditSubtask(subtask)}
                                                                                            >
                                                                                                <TaskIcon />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="left"
                                                                                        sx={{
                                                                                            textDecoration: subtask.is_complete
                                                                                                ? 'line-through'
                                                                                                : 'none',
                                                                                            color: subtask.is_complete
                                                                                                ? 'gray'
                                                                                                : 'inherit',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            subtask.title
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell align="right">
                                                                                        <Checkbox
                                                                                            edge="end"
                                                                                            checked={
                                                                                                subtask.is_complete
                                                                                            }
                                                                                            onChange={() =>
                                                                                                toggleSubtaskCompletion(
                                                                                                    task.id,
                                                                                                    subtask.id
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        )}
                                                                </TableBody>
                                                            </Table>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={sortedTasks.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                ) : (
                    <div>
                        {!project && !quote ? (
                            <h3 style={{ textAlign: 'center' }}>
                                {menuSelection === 3
                                    ? 'Select Quote'
                                    : menuSelection === 2
                                        ? 'Select HSE'
                                        : menuSelection === 1
                                            ? 'Select Service'
                                            : 'Select Project'}
                            </h3>
                        ) : (
                            <h3 style={{ textAlign: 'center' }}>
                                {!titleName && (project || quote) ? 'Select Task List' : 'No Data'}
                            </h3>
                        )}
                    </div>
                )}
            </div>
        );
    }
}