import * as React from 'react';
import WorksegmentDataService from '../services/Worksegment.services';
import PTOServices from '../services/PTO.services';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import WeekPicker from './WeekPicker';
import { v4 as uuidv4 } from 'uuid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Divider, Button } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { pink } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

const cellOverflowStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};

export default function WorksegmentTable(props) {
    const { user, token, worksegments, setWorksegments, PTOsegments, setPTOsegments, totals, isoWeek, setIsoWeek } = props;
    const isAdmin = user.groups.some(group => group.name === 'MANAGEMENT');
    const [tableRows, setTableRows] = React.useState([]);
    const [weekForButton, setWeekForButton] = React.useState(isoWeek);
    const [weekDates, setWeekDates] = React.useState({ start: '', end: '' });
    const [openStates, setOpenStates] = React.useState({});
    const theme = useTheme();

    React.useEffect(() => {
        if (isoWeek) {
            const startOfWeek = moment(isoWeek, 'GGGG[W]WW').startOf('isoWeek');
            const endOfWeek = moment(isoWeek, 'GGGG[W]WW').endOf('isoWeek');
            setWeekDates({
                start: startOfWeek.format('ll'),
                end: endOfWeek.format('ll')
            });
        }
    }, [isoWeek]);

    React.useEffect(() => {
        if (totals && worksegments && isoWeek) {
            updateTable();
        }
    }, [totals, worksegments, isoWeek, PTOsegments]);

    const updateTable = () => {
        // get total hours for all users in isoweek.
        const rows = []
        if (isAdmin) {
            totals.map((d) => {
                rows.push(createData(d['user_name'], d['regular'], d['overtime'], d['doubletime'], d['travel'], d['sick'], d['vacation'], d['holiday'], d['total_duration'], []))
            })
        } else {
            totals.map((d) => {
                if (d.user_id == user.id) {
                    rows.push(createData(d['user_name'], d['regular'], d['overtime'], d['doubletime'], d['travel'], d['sick'], d['vacation'], d['holiday'], d['total_duration'], []))
                }
            })
        }
        rows.map((r) => {
            worksegments.map((w) => {
                if (`${w.user.first_name} ${w.user.last_name}` === r.name) {
                    let number = ''
                    if (w.project) { number = w.project.number }
                    if (w.service) { number = w.service.number }
                    if (w.hse) { number = w.hse.number }
                    if (w.quote) { number = w.quote.number }
                    r.summary.push({
                        id: w.id,
                        approved: w.is_approved ? 'Approved' : 'Pending',
                        date: moment(w.date).format('dddd Do'),
                        project: number,
                        type: w.segment_type.name.charAt(0).toUpperCase() + w.segment_type.name.slice(1),
                        start_time: moment(getDateFromHours(w.start_time)).format('LT'),
                        end_time: moment(getDateFromHours(w.end_time)).format('LT'),
                        travel: w.travel_duration,
                        lunch: w.lunch ? 'Yes' : 'No',
                        total: w.duration,
                        prevailing_rate: w.project && w.project.prevailing_rate // Add prevailing_rate conditionally
                    });
                };
            });
            PTOsegments.map((p) => {
                const fullName = p.user ? `${p.user.first_name} ${p.user.last_name}` : '';
                if (fullName === r.name) {
                    r.summary.push({
                        id: p.id,
                        approved: p.is_approved ? 'Approved' : 'Pending',
                        date: moment(p.date).format('dddd'),
                        project: '----',
                        type: p.is_paid ? p.PTO_type : 'Unpaid',
                        start_time: '----',
                        end_time: '----',
                        travel: '----',
                        lunch: '----',
                        total: p.duration
                    });
                }
            });
        });
        setTableRows(rows);
    };

    const approveWorksegment = (segmentId) => {
        WorksegmentDataService.approveWorksegment(segmentId, token)
            .then(() => {
                const updatedSegments = worksegments.map(segment =>
                    segment.id === segmentId ? { ...segment, is_approved: !segment.is_approved } : segment
                );
                setWorksegments(updatedSegments);
            })
            .catch(e => console.log(e));
    };

    const approvePTO = (segmentId) => {
        PTOServices.approvePTO(segmentId, token)
            .then(response => {
                const updatedSegments = PTOsegments.map(segment =>
                    segment.id === segmentId ? { ...segment, is_approved: !segment.is_approved } : segment
                );
                setPTOsegments(updatedSegments);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const toggleRowOpen = (rowName) => {
        setOpenStates((prevStates) => ({
            ...prevStates,
            [rowName]: !prevStates[rowName]
        }));
    };

    function getDateFromHours(time) {
        const [hours, minutes] = time.split(':').map(Number);
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
    }

    const handleMoveDate = (direction) => {
        const newIsoWeek = moment(isoWeek, 'GGGG[W]WW').add(direction, 'week').format('GGGG[W]WW');
        setIsoWeek(newIsoWeek); // Directly set the isoWeek, triggering updates in WeekPicker
    };

    return (
        <div style={{ width: '100%', marginTop: '20px' }}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'left' }}>
                <WeekPicker getIsoWeek={setIsoWeek} isoWeek={isoWeek} weekForButton={weekForButton} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Stack direction="row" spacing={2}>
                        <IconButton size="large" color="primary" aria-label="back" onClick={() => handleMoveDate(-1)}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton size="large" color="primary" aria-label="forward" onClick={() => handleMoveDate(1)}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Stack>
                </Box>
            </Stack>
            <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
                <Typography variant="subtitle1">{weekDates.start}</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="subtitle1">{weekDates.end}</Typography>
            </Stack>

            <TableContainer component={Box} sx={{ mt: 3, width: '100%' }}>
                <Table stickyHeader size="small" aria-label="collapsible table" sx={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '5%' }} />
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '15%' }}>Employee</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Regular</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Overtime</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Double</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Travel</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Sick</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Vacation</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Holiday</TableCell>
                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Total Hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows.map((row) => (
                            <React.Fragment key={uuidv4()}>
                                <TableRow
                                    hover
                                    onClick={() => toggleRowOpen(row.name)}
                                    sx={{
                                        '&:not(:last-child) > *': openStates[row.name] ? {} : {
                                            borderBottom: 'none', // Removes the border only when not open
                                        }
                                    }}
                                >
                                    <TableCell sx={{ width: '5%' }}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={(event) => {
                                                event.stopPropagation(); // Prevents the event from bubbling up
                                                toggleRowOpen(row.name);
                                            }}
                                        >
                                            {openStates[row.name] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{ ...cellOverflowStyle, width: '15%' }}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.regular == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.regular == 0 ? '----' : row.regular}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.overtime == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.overtime == 0 ? '----' : row.overtime}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.doubletime == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.doubletime == 0 ? '----' : row.doubletime}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.travel == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.travel == 0 ? '----' : row.travel}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.sick == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.sick == 0 ? '----' : row.sick}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.vacation == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.vacation == 0 ? '----' : row.vacation}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.holiday == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.holiday == 0 ? '----' : row.holiday}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            ...cellOverflowStyle,
                                            width: '10%',
                                            color: row.total_duration == 0 ? theme.palette.text.disabled : 'inherit'
                                        }}
                                    >
                                        {row.total_duration == 0 ? '----' : row.total_duration}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                                        <Collapse in={openStates[row.name]} timeout="auto" unmountOnExit>
                                            <Box component={Paper} elevation={1} sx={{ margin: 1, padding: 2, width: '100%' }}>
                                                <Typography variant="h6" gutterBottom>{`${isoWeek} Summary`}</Typography>
                                                <Table size="small" sx={{ width: '100%' }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Status</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Date</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Project</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Type</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Start Time</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>End Time</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Travel</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Lunch</TableCell>
                                                            <TableCell align="left" style={{ ...cellOverflowStyle, width: '10%' }}>Total Hours</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.summary.map((summaryRow) => (
                                                            <TableRow
                                                                hover
                                                                key={uuidv4()}
                                                                sx={{
                                                                    border: summaryRow.prevailing_rate && isAdmin ? `2px solid ${pink[500]}` : 'none',
                                                                    borderRadius: '5px',
                                                                }}
                                                            >
                                                                <TableCell component="th" scope="row"
                                                                    sx={{
                                                                        width: 'auto', 
                                                                        minWidth: '135px', 
                                                                        ...cellOverflowStyle, 
                                                                    }}
                                                                >
                                                                    {isAdmin ? (
                                                                        summaryRow.approved === 'Approved' ? (
                                                                            <Button
                                                                                variant="outlined"
                                                                                startIcon={<CheckBoxIcon />}
                                                                                color="success"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    if (summaryRow.project === '----') {
                                                                                        approvePTO(summaryRow.id); // Call approvePTO for PTO segments
                                                                                    } else {
                                                                                        approveWorksegment(summaryRow.id); // Call approveWorksegment for regular work segments
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Approved
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="inherit"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    if (summaryRow.project === '----') {
                                                                                        approvePTO(summaryRow.id); // Call approvePTO for PTO segments
                                                                                    } else {
                                                                                        approveWorksegment(summaryRow.id); // Call approveWorksegment for regular work segments
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Approve
                                                                            </Button>
                                                                        )
                                                                    ) : (
                                                                        `${summaryRow.approved === 'Approved' ? 'Approved' : 'Pending'}`
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.date}</TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.project}</TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.type}</TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.start_time}</TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.end_time}</TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.travel}</TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.lunch}</TableCell>
                                                                <TableCell align="left" style={cellOverflowStyle}>{summaryRow.total}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function createData(name, regular, overtime, doubletime, travel, sick, vacation, holiday, total_duration, summary) {
    return {
        name,
        regular,
        overtime,
        doubletime,
        travel,
        sick,
        vacation,
        holiday,
        total_duration,
        summary
    };
}