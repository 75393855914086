import React, { createContext, useState, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Create the loading context
export const LoadingContext = createContext();

// Create the loading context provider
export default function LoadingContextProvider ({ children }) {
    const [isLoading, setIsLoading] = useState(false);

    // Value object to be passed to consumers
    const value = {
        isLoading,
        setIsLoading: setIsLoading,
    };

    return (
        <LoadingContext.Provider value={value}>
            {children}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </LoadingContext.Provider>
    );
};

// Create the useLoading hook
export const useLoading = () => {
    const context = useContext(LoadingContext);

    if (context === undefined) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }

    return context;
};
