import * as React from 'react';
import TaskDataService from '../../services/Task.services';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

export default function ProjectTaskListPicker(props) {
    // const [taskLists, setTaskLists] = React.useState([]);
    const { taskLists } = props;
    const { handleChangeList } = props;
    const { errors } = props;
    const { value, setValue } = props;

    const handleChange = (event) => {
        setValue(event.target.value);
        handleChangeList(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth error={errors.tasklist}>
                <InputLabel
                    id="select-list-label"
                >
                    Select Task List
                </InputLabel>
                <Select
                    labelId="select-list-label"
                    id="select-list"
                    renderValue={(v) => <span>{v.title}</span>}
                    value={value}
                    label="Select Task List"
                    onChange={handleChange}
                >

                    {taskLists.map(list => (
                        <MenuItem
                            key={list.id}
                            value={list}
                        >
                                {list.title}
                                {list.count ? (
                                    list.count === 0 ? (
                                        ''
                                    ) : (
                                        <Chip
                                            sx={{ ml: 1 }}
                                            size="small"
                                            label={list.count}
                                            color="primary"
                                        />
                                    )
                                ) : (
                                    ''
                                )}
                        </MenuItem>
                    )
                    )}
                </Select>
                {errors.tasklist && <FormHelperText error={errors.tasklist ? true : false}>This is required!</FormHelperText>}
            </FormControl>
        </Box>
    );
};