import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from './components/MainRoutes';
import NavBar from './components/NavBar';
import UserService from "./services/User.services";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SnackbarAlert from './components/SnackbarAlert';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loading from './components/Loading';
import LoadingContextProvider from '../src/contexts/loading-context';
import EmployeeContextProvider from '../src/contexts/employee-context';
import UserContextProvider from '../src/contexts/user-context';
import SnackbarContextProvider from '../src/contexts/snackbar-context';
import WorksegmentDataService from './services/Worksegment.services';
import TaskDataService from './services/Task.services';
import moment from 'moment';
import { purple } from '@mui/material/colors';
import packageJson from '../package.json';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// export const UserContext = React.createContext();

export default function App() {
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '' || {})
    const [token, setToken] = useState(localStorage.getItem('token') || null)
    const [error, setError] = useState('')
    const [loginErrors, setLoginErrors] = useState({ username: null, password: null })
    const [isLoading, setIsLoading] = React.useState(false);

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('')
    const [snackbarMessage, setSnackbarMessage] = React.useState('')

    const getTheme = localStorage.getItem('theme');
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkState, setDarkState] = useState(prefersDarkMode);
    let palletType = darkState ? "dark" : "light";

    const [worksegments, setWorksegments] = React.useState([]);
    const [PTOsegments, setPTOsegments] = useState([]);
    const [employees, setEmployees] = React.useState([])
    const [taskRead, setTaskRead] = React.useState(0);

    const [totals, setTotals] = React.useState([]);
    const [isoWeek, setIsoWeek] = React.useState(moment(new Date()).format('GGGG[W]WW'));
    const [openDrawer, setOpenDrawer] = React.useState(false);

    // Version check useEffect
    React.useEffect(() => {
        const currentVersion = packageJson.version;
        const lastVersion = localStorage.getItem('appVersion');
        if (lastVersion === null) {
            localStorage.setItem('appVersion', currentVersion);
        } else if (lastVersion !== currentVersion) {
            localStorage.setItem('appVersion', currentVersion);
            // Optionally, notify the user about the update
            handleOpenSnackbar('info', `Updated to version ${currentVersion}`);
        }
    }, []);

    // Service worker registration and update handling
    React.useEffect(() => {
        serviceWorkerRegistration.register({
            onUpdate: registration => {
                if (registration && registration.waiting) {
                    // Send message to the waiting service worker to skip waiting
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });

                    // Listen for the state change of the service worker
                    registration.waiting.addEventListener('statechange', e => {
                        if (e.target.state === 'activated') {
                            // New service worker has activated
                            // Reload the page to load the new content
                            window.location.reload();
                        }
                    });
                }
            }
        });
    }, []);

    const theme = createTheme({
        palette: {
            background: {
                default: darkState ? '#1D1F25' : "#f8f8ff",
                paper: darkState ? '#26272A' : '#ffff'
            },
            mode: palletType,
            primary: {
                main: '#008093'
            },
            secondary: {
                main: purple[500],
            },
            darkBlue: {
                main: '#01557f'
            },
        },
    });

    useEffect(() => {
        if (getTheme) {
            getTheme === 'dark' ? setDarkState(true) : setDarkState(false)
        }
    }, []);

    useEffect(() => {
        if (user.username) {
            retrieveTaskReadCount();
        }
    }, [openDrawer])

    useEffect(() => {
        localStorage.setItem('theme', palletType)
    }, [palletType])

    const handleChangeMode = () => {
        setDarkState(!darkState);
    }

    async function login(user = null) {
        setIsLoading(true);
        UserService.login(user)
            .then(response => {
                setLoginErrors({ username: null, password: null });
                setToken(response.data.token);
                setUser(JSON.parse(response.data.userObject))
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(JSON.parse(response.data.userObject)));
                setError('');
            })
            .catch(e => {
                handleOpenSnackbar('error', 'Unauthorized User')
                window.location.reload();
                console.log('login', e.toString());
                setLoginErrors({ username: 'Username', password: 'Password' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    async function logout() {
        const userData = Object.keys(user);
        setToken('');
        setUser(userData);
        localStorage.setItem('token', '');
        localStorage.setItem('user', JSON.stringify(userData));
    };

    async function signup(user = null) {
        UserService.signup(user)
            .then(response => {
                setToken(response.data.token);
                setUser(user.username);
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', user.username);
            })
            .catch(e => {
                console.log('login', e);
                setError(e.toString());
            });
    };

    const handleOpenSnackbar = (severity, message) => {
        setOpenSnackbar(true);
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    async function isActive() {
        // check if user is active
        setIsLoading(true);
        UserService.isActive({ 'username': user.username })
            .then(response => {
                if (response.data) {
                    let active = response.data.user_is_active
                    if (active === 'True') {
                        retrieveAllSegments();
                    } else {
                        // if user is not active anymore logout.
                        setToken('')
                        localStorage.setItem('token', '')
                        localStorage.setItem('user', '');
                        handleOpenSnackbar('error', 'Unauthorized User')
                        window.location.reload();
                    }
                }

            })
            .catch(e => {
                setIsLoading(false);
            })
    };

    // -------- worksegments --------- //

    React.useLayoutEffect(() => {
        if (typeof user === 'object' &&
            !Array.isArray(user) &&
            user !== null) {
            isActive();
        } else {
            setIsLoading(false)
        }
    }, [isoWeek, user]);

    const retrieveAllSegments = () => {
        WorksegmentDataService.getAllSegments(token, isoWeek)
            .then(response => {
                const newEmployee = response.data.active_users;
                const data = response.data.totals;
                setEmployees(newEmployee);

                // Set totals based on the returned total fields for each user
                const newTotals = data.map(item => ({
                    user_id: item.user_id,
                    user_name: item.user_name,
                    isoweek: item.isoweek,
                    regular: item.regular,
                    overtime: item.overtime,
                    doubletime: item.doubletime,
                    travel: item.travel,
                    sick: item.sick,
                    vacation: item.vacation,
                    holiday: item.holiday,
                    bereavement: item.bereavement,
                    total_duration: item.total_duration
                }));
                setTotals(newTotals);

                // Set worksegments and PTOsegments from the response
                const newWorksegments = data.flatMap(item => item.work_segments);
                const newPTOsegments = data.flatMap(item => item.pto_segments);
                setWorksegments(newWorksegments);
                setPTOsegments(newPTOsegments);
                setIsLoading(false);
            })
            .catch(e => {
                console.log(e);
                handleOpenSnackbar('error', 'Something Went Wrong!! Please try again.');
                setIsLoading(false);
            });
    };

    const retrieveTaskReadCount = () => {
        TaskDataService.getReadCount(token, user.id)
            .then(response => {
                setTaskRead(response.data.count)
            })
            .catch(e => {
                console.log(e);
                handleOpenSnackbar('error', 'Something Went Wrong!! Please try again. read count')
            })
    };

    return (
        <ThemeProvider theme={theme}>
            <LoadingContextProvider>
                <UserContextProvider>
                    <SnackbarContextProvider>
                        <EmployeeContextProvider>
                            <CssBaseline />
                            <BrowserRouter>
                                {user.username ?
                                    <>
                                        <NavBar
                                            handleChangeMode={handleChangeMode}
                                            darkState={darkState}
                                            user={user}
                                            logout={logout}
                                            taskRead={taskRead}
                                            open={openDrawer}
                                            setOpen={setOpenDrawer}
                                        />
                                    </> : ''}
                                <MainRoutes
                                    user={user}
                                    token={token}
                                    setToken={setToken}
                                    login={login}
                                    signup={signup}
                                    logout={logout}
                                    error={error}
                                    loginErrors={loginErrors}
                                    darkState={darkState}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    handleChangeMode={handleChangeMode}
                                    worksegments={worksegments}
                                    setWorksegments={setWorksegments}
                                    totals={totals}
                                    setTotals={setTotals}
                                    PTOsegments={PTOsegments}
                                    setPTOsegments={setPTOsegments}
                                    isoWeek={isoWeek}
                                    setIsoWeek={setIsoWeek}
                                    employees={employees}
                                />
                                <SnackbarAlert
                                    openSnackbar={openSnackbar}
                                    handleCloseSnackbar={handleCloseSnackbar}
                                    severity={snackbarSeverity}
                                    message={snackbarMessage} />
                            </BrowserRouter>
                            <Loading
                                open={isLoading}
                            />
                        </EmployeeContextProvider>
                    </SnackbarContextProvider>
                </UserContextProvider>
            </LoadingContextProvider>
        </ThemeProvider>
    );
};