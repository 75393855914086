import React, { createContext, useState, useEffect, useContext } from 'react';
import UserService from '../services/User.services';
import { UserContext } from './user-context';
import { useLoading } from './loading-context';

export const EmployeeContext = createContext();

export default function EmployeeContextProvider({ children }) {
    const [employees, setEmployees] = useState([]);
    const { isLoading, setIsLoading } = useLoading();
    const [error, setError] = useState(null);

    const { token } = useContext(UserContext);

    useEffect(() => {
        if(token){
            retrieveEmployees(token);
        }
    }, [token]);
    
    const retrieveEmployees = (token) => {
        setIsLoading(true);
        UserService.getUsers(token)
            .then(response => {
                setEmployees(response.data);
            })
            .catch(e => {
                console.log(e);
                setError('Failed to fetch employees');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const value = {
        employees,
        error
    };

    return (
        <EmployeeContext.Provider value={value}>
            {children}
        </EmployeeContext.Provider>
    );
}

export const useEmployees = () => {
    const context = useContext(EmployeeContext);
    if (!context) {
        throw new Error('useEmployees must be used within a EmployeeContextProvider');
    }
    return context;
};