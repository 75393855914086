import React, { createContext, useReducer } from 'react';
import UserService from "../services/User.services";
import { useContext } from 'react';

export const UserContext = createContext();

const initialState = {
    user: JSON.parse(localStorage.getItem('user')) || {},
    token: localStorage.getItem('token') || null,
    error: '',
    loginErrors: { username: null, password: null }
};

function reducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                loginErrors: { username: null, password: null },
                error: ''
            };
        case 'LOGOUT':
            console.log('logout');
            localStorage.setItem('token', '');
            localStorage.setItem('user', JSON.stringify({}));
            return {
                ...state,
                user: {},
                token: null
            };
        case 'SIGNUP':
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('user', action.payload.username);
            return {
                ...state,
                user: action.payload.username,
                token: action.payload.token
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };
        case 'SET_LOGIN_ERRORS':
            return {
                ...state,
                loginErrors: action.payload
            };
        default:
            throw new Error();
    }
}

export default function UserContextProvider (props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    async function login(user = null) {
        return UserService.login(user)
            .then(response => {
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        token: response.data.token,
                        user: JSON.parse(response.data.userObject)
                    }
                });
            })
            .catch(e => {
                console.log('login', e.toString());
                dispatch({
                    type: 'SET_LOGIN_ERRORS',
                    payload: { username: 'Username', password: 'Password' }
                });
            });
    };

    async function logout() {
        dispatch({ type: 'LOGOUT' });
    };

    async function signup(user = null) {
        UserService.signup(user)
            .then(response => {
                dispatch({
                    type: 'SIGNUP',
                    payload: {
                        token: response.data.token,
                        username: user.username
                    }
                });
            })
            .catch(e => {
                console.log('login', e);
                dispatch({
                    type: 'SET_ERROR',
                    payload: e.toString()
                });
            });
    };

    return (
        <UserContext.Provider value={{ ...state, token: state.token, login, logout, signup }}>
            {props.children}
        </UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);

    if (context === undefined) {
        throw new Error('useUser must be used within a UserContextProvider');
    }

    return context;
}