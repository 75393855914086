import React from 'react';
import AddProjectTaskForm from '../components/forms/AddProjectTaskForm';
import TaskDataService from '../services/Task.services';
import TaskListPicker from '../components/TaskListPicker';
import ProjectTaskListPicker from '../components/ProjectTask/ProjectTaskListPicker';
import ProjectPicker from '../components/ProjectPicker';
import QuotePicker from '../components/QuotePicker';
import ServicePicker from '../components/ServicePicker';
import HSEPicker from '../components/HSEPicker';
import { Container, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ProjectTypeDropdown from '../components/ProjectTypeDropdown';
import ProjectTaskTable from '../components/ProjectTask/ProjectTaskTable';
import { useLoading } from '../contexts/loading-context';
import { useEmployees } from '../contexts/employee-context';

export default function ProjectTask(props) {
    const { token, user, handleOpenSnackbar } = props;
    const [project, setProject] = React.useState(null);
    const [quote, setQuote] = React.useState(null);
    const [lookup, setLookup] = React.useState('');
    const [tasks, setTasks] = React.useState(null);
    const [editing, setEditing] = React.useState(false);
    const [editTask, setEditTask] = React.useState(null);
    const [filteredTasks, setFilteredTasks] = React.useState(null);
    const [menuOptions, setMenuOptions] = React.useState(['Projects', 'Services', "HSE's"]);
    const [menuSelection, setMenuSelection] = React.useState(0);
    const [picker, setPicker] = React.useState('');
    const [taskList, setTaskList] = React.useState([]);
    const [openTaskForm, setOpenTaskForm] = React.useState(false);

    const [taskLists, setTaskLists] = React.useState([]);
    const [ list, setList ] = React.useState('');

    const { employees } = useEmployees();
    const { setIsLoading } = useLoading();

    React.useEffect(() => {
        retrieveTaskList();
        if (user.groups.filter(group => (group.name === 'SALES')).length > 0) {
            setMenuOptions(['Projects', 'Services', "HSE's", 'Quotes']);
        }
    }, [])
    

    const updateTasksFromFiltered = (id) => {
        const updatedTasks = tasks.filter(task => task.id !== id);
        setTasks(updatedTasks);
    };

    const updateSubTasksFromFiltered = (taskId, subtaskId) => {
        const updatedTasks = tasks.map(task => {
            if (task.id === taskId) {
                task.subtasks = task.subtasks.filter(subtask => subtask.id !== subtaskId);
            }
            return task;
        });
        setTasks(updatedTasks);

    };

    React.useEffect(() => {
        setList('');
        if (project && project.id) {
            getTasks(project.id);
        } else if (quote && quote.id) {
            getTasks(quote.id);
        } else {
            setTasks([]);
        }
    }, [project, quote]);

    const getTasks = (id) => {
        setIsLoading(true);
        TaskDataService.getAllTasksFiltered(token, lookup, id)
            .then((response) => {
                setIsLoading(false);
    
                const newTasks = response.data;
    
                // Count tasks for each tasklist.title
                const taskCounts = newTasks.reduce((acc, task) => {
                    const tasklist = task.tasklist; // Safely access tasklist
                    if (tasklist && tasklist.title) { // Check for null/undefined
                        const title = tasklist.title;
                        acc[title] = (acc[title] || 0) + 1;
                    }
                    return acc;
                }, {}); // Initialize as an empty object
    
                // Update taskLists with the count
                setTaskLists((prevTaskLists) =>
                    prevTaskLists.map((list) => ({
                        ...list,
                        count: taskCounts[list.title] ? taskCounts[list.title] : 0, // Use taskCounts or default to 0
                    }))
                );
    
                setTasks(newTasks); // Optionally store the raw tasks response
            })
            .catch((e) => {
                setIsLoading(false);
                console.error('Error fetching tasks:', e);
            });
    };

    const retrieveTaskList = () => {
        setIsLoading(true);
        TaskDataService.getAllTaskList(token)
            .then(response => {
                const updatedTaskLists = response.data.map(list => ({
                    ...list,
                    count: 0, // Initialize count to 0
                })).sort((a, b) => (a.title > b.title ? 1 : -1));

                setTaskLists(updatedTaskLists);
            })
            .catch(e => {
                console.error(e);
                handleOpenSnackbar('error', 'Something Went Wrong!! Please try again.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        // if (didMount.current) {
        switch (menuSelection) {
            case 1:
                // console.log('Services')
                handleClear();
                setPicker(
                    <ServicePicker
                        token={token}
                        handleChangeProject={handleChangeProject}
                        errors={{ project: '' }}
                    />
                )
                setLookup('service');
                break;
            case 2:
                // console.log("HSE's")
                handleClear();
                setPicker(
                    <HSEPicker
                        token={token}
                        handleChangeProject={handleChangeProject}
                        errors={{ project: '' }}
                    />
                )
                setLookup('hse');
                break;
            case 3:
                // console.log('Quotes')
                handleClear();
                setPicker(
                    <QuotePicker
                        token={token}
                        handleChangeQuote={handleChangeQuote}
                        errors={{ quote: '' }}
                    />
                )
                setLookup('quote');
                break;
            default:
                // console.log('Projects')
                handleClear();
                setPicker(
                    <ProjectPicker
                        token={token}
                        handleChangeProject={handleChangeProject}
                        errors={{ project: '' }}
                    />
                )
                setLookup('project');
        }
    }, [menuSelection]);

    const handleChangeProject = (newProject) => {
        setProject(newProject);
        setQuote(null);
    };

    const handleChangeQuote = (newQuote) => {
        setQuote(newQuote);
        setProject(null);
    };

    const handleClear = () => {
        setProject(null);
        setQuote(null);
    };

    const handleChangeList = (list) => {
        setTaskList(list);
        let filteredTasks;
        // Only filter tasks if a taskList is chosen
        if (tasks) {
            filteredTasks = tasks.filter(
                (task) => task.tasklist && task.tasklist.id === list.id
            );
        } else {
            filteredTasks = []; // Show all tasks if no taskList is selected
        }
        setFilteredTasks(filteredTasks);
    };

    const handleOpenTaskForm = () => {
        setOpenTaskForm(true);
    };

    const createTask = (task) => {
        setIsLoading(true);
        TaskDataService.createTask(task, token)
            .then((response) => {
                let newTask = response.data;
                newTask.created_by = employees.find(
                    (employee) => employee.id === newTask.created_by
                );
                newTask.assignee = employees.find(
                    (employee) => employee.id === newTask.assignee
                );
                newTask.tasklist = taskList;
                newTask.project = newTask.project ? project : null;
                newTask.service = newTask.service ? project : null;
                newTask.hse = newTask.hse ? project : null;
                newTask.quote = newTask.quote ? quote : null;

                // Filter out tasks where is_public is false
                const updatedTasks = [...tasks, newTask].filter((task) => task.is_public);
                setTasks(updatedTasks);

                // Filter tasks into the correct task list
                if (taskList && taskList.id) {
                    const updatedFilteredTasks = updatedTasks.filter(
                        (task) => task.tasklist && task.tasklist.id === taskList.id
                    );
                    setFilteredTasks(updatedFilteredTasks);
                } else {
                    setFilteredTasks(updatedTasks); // Show all tasks if no taskList is selected
                }

                setIsLoading(false);
                handleOpenSnackbar('success', 'Task has been created');
            })
            .catch((e) => {
                setIsLoading(false);
                handleOpenSnackbar('error', 'Something Went Wrong!! Please try again.');
                console.error(e);
            });
    };

    const updateTask = (id, task) => {
        setIsLoading(true);
        TaskDataService.updateTask(id, task, token)
            .then((response) => {
                let updatedTask = response.data;
                updatedTask.created_by = employees.find(
                    (employee) => employee.id === updatedTask.created_by
                );
                updatedTask.assignee = employees.find(
                    (employee) => employee.id === updatedTask.assignee
                );
                updatedTask.tasklist = taskList;
                updatedTask.project = updatedTask.project ? project : null;
                updatedTask.service = updatedTask.service ? project : null;
                updatedTask.hse = updatedTask.hse ? project : null;
                updatedTask.quote = updatedTask.quote ? quote : null;

                // Update tasks, filtering out tasks where is_public is false
                const updatedTasks = tasks
                    .map((task) => (task.id === updatedTask.id ? updatedTask : task))
                    .filter((task) => task.is_public);
                setTasks(updatedTasks);

                // Filter tasks into the correct task list
                if (taskList && taskList.id) {
                    const updatedFilteredTasks = updatedTasks.filter(
                        (task) => task.tasklist && task.tasklist.id === taskList.id
                    );
                    setFilteredTasks(updatedFilteredTasks);
                } else {
                    setFilteredTasks(updatedTasks); // Show all tasks if no taskList is selected
                }

                setIsLoading(false);
                handleOpenSnackbar('success', 'Task has been updated');
            })
            .catch((e) => {
                setIsLoading(false);
                handleOpenSnackbar('error', 'Something Went Wrong!! Please try again.');
                console.error(e);
            });
    };

    return (
        <div style={{ paddingTop: '1rem' }}>
            <Container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                <div style={{ width: '100%', maxWidth: '400px', marginTop: '1.5rem' }}>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={1}>
                            {picker}
                            <ProjectTypeDropdown
                                user={user}
                                menuOptions={menuOptions}
                                menuSelection={menuSelection}
                                setMenuSelection={setMenuSelection}
                            />
                        </Stack>
                        {(project && project.id) || (quote && quote.id) ? (
                            <Stack spacing={1} direction="row">
                                <div style={{ width: '50%' }}>
                                    <ProjectTaskListPicker
                                        handleChangeList={handleChangeList}
                                        errors={{ tasklist: false }}
                                        taskLists={taskLists}
                                        value={list}
                                        setValue={setList}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <Button
                                        disabled={!taskList || taskList.length === 0}
                                        sx={{ height: '100%' }}
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="success"
                                        endIcon={<AddIcon />}
                                        onClick={handleOpenTaskForm}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Stack>
                        ) : null}
                    </Stack>
                </div>
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '100%'
                    }}>
                    <ProjectTaskTable
                        user={user}
                        token={token}
                        handleOpenSnackbar={handleOpenSnackbar}
                        project={project}
                        quote={quote}
                        taskList={taskList}
                        tasks={filteredTasks}
                        setTasks={setFilteredTasks}
                        menuSelection={menuSelection}
                        openTaskForm={openTaskForm}
                        setOpenTaskForm={setOpenTaskForm}
                        editing={editing}
                        setEditing={setEditing}
                        setEditTask={setEditTask}
                        updateTask={updateTask}
                        updateTasksFromFiltered={updateTasksFromFiltered}
                        updateSubTasksFromFiltered={updateSubTasksFromFiltered}
                    />
                </Container>
            </Container>
            <AddProjectTaskForm
                open={openTaskForm}
                setOpen={setOpenTaskForm}
                task={editTask ? editTask : {}}
                editing={editing}
                setEditing={setEditing}
                project={project}
                quote={quote}
                lookup={lookup}
                taskList={taskList}
                createTask={createTask}
                updateTask={updateTask}
                setEditTask={setEditTask}

            />
        </div>
    );
};