import * as React from 'react';
import TaskDataService from '../../services/Task.services';
import { useLoading } from '../../contexts/loading-context';
import DeleteTaskModal from '../DeleteTaskModal';
import AddSubtaskForm from '../AddSubtaskForm';
import { styled, alpha } from '@mui/material/styles';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PreviewIcon from '@mui/icons-material/Preview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ProjectTaskDialog from './ProjectTaskDialog';
import { Tooltip } from '@mui/material';
import { is } from 'date-fns/locale';
import { set } from 'date-fns';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                // color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
        ...theme.applyStyles('dark', {
            color: theme.palette.grey[300],
        }),
    },
}));

export default function TaskMenu(props) {
    const { task, tasks, setTasks, token, user, handleOpenSnackbar } = props;
    const { openTaskForm, setOpenTaskForm } = props;
    const { editing, setEditing, setEditTask } = props;
    const { subtaskEditing, setSubtaskEditing } = props;
    const { openSubtask, setOpenSubtask } = props;
    const { updateTask, updateTasksFromFiltered } = props;
    const { subtask, setSubtask, handleSubtaskDeleted } = props;
    const [openPreview, setOpenPreview] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { setIsLoading } = useLoading();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditTask = () => {
        setEditTask(task);
        setOpenTaskForm(true);
        setEditing(true);
        handleClose();
    };

    const handleDelete = () => {
        setOpenDelete(true);
        handleClose();

    };

    const handlePreview = () => {
        setOpenPreview(true);
        handleClose();
    };

    const deleteTask = (id) => {
        setIsLoading(true);
        TaskDataService.deleteTask(id, token)
            .then(response => {
                handleOpenSnackbar('warning', 'Tasks has been deleted')
                const updatedTasks = tasks.filter(task => task.id !== id);
                setTasks(updatedTasks);
                updateTasksFromFiltered(id);
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
                handleOpenSnackbar('error', 'Something Went Wrong!! Please try again.')
            })
            .finally(() => {
                setIsLoading(false);
                handleClose();
            });
    };

    const completeTask = (id) => {
        setIsLoading(true);
        TaskDataService.completeTask(id, token)
            .then(response => {
                // The API only returns the ID of the updated task
                const updatedTaskId = response.data.id;

                // Find the updated task in the state to determine its current `is_complete` value
                const updatedTasks = tasks.map(task => {
                    if (task.id === updatedTaskId) {
                        // Toggle the `is_complete` value locally
                        return { ...task, is_complete: !task.is_complete };
                    }
                    return task;
                });

                // Get the updated task to show the correct message
                const updatedTask = updatedTasks.find(task => task.id === updatedTaskId);

                handleOpenSnackbar(
                    'success',
                    updatedTask.is_complete ? 'Task has been completed' : 'Task has been marked as incomplete'
                );

                setTasks(updatedTasks); // Update the state with the toggled task
            })
            .catch(e => {
                console.error("Error during task update:", e);
                handleOpenSnackbar('error', 'Something went wrong! Please try again.');
            })
            .finally(() => {
                setIsLoading(false);
                handleClose();
            });
    };

    const createSubtask = (data) => {
        setIsLoading(true);
        TaskDataService.createSubtask(data, token)
            .then(response => {
                handleOpenSnackbar('success', 'Subtask has been created');
                const newSubtask = response.data;

                // Ensure task.created_by and task.assignee are numbers
                let updatedTask = { ...task };
                updatedTask.created_by = typeof task.created_by === 'object' ? task.created_by.id : task.created_by;
                updatedTask.assignee = typeof task.assignee === 'object' ? task.assignee.id : task.assignee;
                updatedTask.tasklist = typeof task.tasklist === 'object' ? task.tasklist.id : task.tasklist;
                updatedTask.project = task.project ? (typeof task.project === 'object' ? task.project.id : task.project) : null;
                updatedTask.service = task.service ? (typeof task.service === 'object' ? task.service.id : task.service) : null;
                updatedTask.hse = task.hse ? (typeof task.hse === 'object' ? task.hse.id : task.hse) : null;
                updatedTask.quote = task.quote ? (typeof task.quote === 'object' ? task.quote.id : task.quote) : null;

                // Filter and sanitize subtasks
                updatedTask.subtasks = Array.isArray(task.subtasks)
                    ? task.subtasks
                        .map(subtask => {
                            if (typeof subtask === 'object' && subtask !== null && 'id' in subtask) {
                                return subtask.id; // Extract the ID from the object
                            }
                            if (typeof subtask === 'number') {
                                return subtask; // Keep valid numbers
                            }
                            return null; // Invalid entries become null
                        })
                        .filter(id => Number.isInteger(id)) // Retain only integers
                    : [];

                // Append the new subtask ID
                if (newSubtask && newSubtask.id) {
                    updatedTask.subtasks.push(newSubtask.id); // Add the new subtask ID to the sanitized list
                }

                // Update the task with the sanitized and updated subtasks
                updateTask(task.id, updatedTask);
                setSubtask({});
            })
            .catch(e => {
                console.error("Error during subtask creation:", e);
                handleOpenSnackbar('error', 'Something went wrong! Please try again.');
            })
            .finally(() => {
                setIsLoading(false);
                handleClose();
            });
    };

    const updateSubtask = (subtaskId, updatedData) => {
        setIsLoading(true);
        TaskDataService.updateSubtask(subtaskId, updatedData, token)
            .then((response) => {
                handleOpenSnackbar('success', 'Subtask has been updated');
                const updatedSubtask = response.data;

                // Update the task's subtasks list in the parent state
                setTasks((prevTasks) =>
                    prevTasks.map((taskItem) =>
                        taskItem.id === task.id
                            ? {
                                ...taskItem,
                                subtasks: taskItem.subtasks.map((subtask) =>
                                    subtask.id === subtaskId ? updatedSubtask : subtask
                                ),
                            }
                            : taskItem
                    )
                );

                setSubtask({});
                setOpenSubtask(false);
            })
            .catch((e) => {
                console.error('Error during subtask update:', e);
                handleOpenSnackbar('error', 'Something went wrong! Please try again.');
            })
            .finally(() => {
                setIsLoading(false);
                handleClose();
            });
    };

    const deleteSubtask = (id) => {
        setIsLoading(true);
        TaskDataService.deleteSubtask(id, token)
            .then((response) => {
                handleOpenSnackbar('warning', 'Subtask has been deleted');
                handleSubtaskDeleted(task.id, id); 
            })
            .catch((e) => {
                console.error("Error during subtask deletion:", e);
                handleOpenSnackbar('error', 'Something went wrong! Please try again.');
            })
            .finally(() => {
                setIsLoading(false);
                handleClose();
            });
    };

    return (
        <div>
            <Tooltip title="Task Menu" arrow>
                <IconButton
                    id="task-menu-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    onClick={handleClick}
                    aria-label="delete"
                >
                    <MenuOpenIcon />
                </IconButton>
            </Tooltip>
            <StyledMenu
                key={task.id}
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {/* Always show the Preview option */}
                <MenuItem
                    sx={{ color: 'primary.main' }}
                    disableRipple
                    onClick={handlePreview}
                >
                    <PreviewIcon />
                    Preview
                </MenuItem>

                {/* If the task is complete, show the "Uncomplete Task" option */}
                {task && task.is_complete ? (
                    <MenuItem
                        sx={{ mb: 2, color: 'warning.main' }}
                        disableRipple
                        onClick={() => completeTask(task.id)}
                    >
                        <RemoveCircleOutlineIcon />
                        Uncomplete Task
                    </MenuItem>
                ) : (
                    /* If the task is not complete, show other options */
                    <div>
                        {(task.assignee.id === user.id || task.created_by.id === user.id) && (
                            <MenuItem
                                sx={{ color: 'primary.main' }}
                                disableRipple
                                onClick={handleEditTask}
                            >
                                <EditIcon />
                                Edit
                            </MenuItem>
                        )}
                        {(task.assignee.id === user.id || task.created_by.id === user.id) && (
                            <MenuItem
                                sx={{ color: 'primary.main' }}
                                disableRipple
                                onClick={() => setOpenSubtask(true)}
                            >
                                <PlaylistAddIcon />
                                Add Subtask
                            </MenuItem>
                        )}
                        {task.assignee.id === user.id && (
                            <MenuItem
                                sx={{ mb: 2, color: 'success.main' }}
                                disableRipple
                                onClick={() => completeTask(task.id)}
                            >
                                <CheckCircleIcon />
                                Complete Task
                            </MenuItem>
                        )}
                        {(task.assignee.id === user.id || task.created_by.id === user.id) && (
                            <div>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    sx={{ color: 'error.dark', mt: 2 }}
                                    disableRipple
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon />
                                    Delete
                                </MenuItem>
                            </div>
                        )}
                    </div>
                )}
            </StyledMenu>
            <DeleteTaskModal
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                task={task}
                deleteTask={deleteTask}
            />
            <ProjectTaskDialog
                openTaskDialog={openPreview}
                setOpenTaskDialog={setOpenPreview}
                task={task}
                editing={editing}
                setEditing={setEditing}
            />
            <AddSubtaskForm
                editing={subtaskEditing}
                setEditing={setSubtaskEditing}
                open={openSubtask}
                setOpenSubtaskForm={setOpenSubtask}
                subtask={subtask}
                setSubtask={setSubtask}
                task={task}
                tasks={tasks}
                setTasks={setTasks}
                createSubtask={createSubtask}
                updateSubtask={updateSubtask}
                deleteSubtask={deleteSubtask}
                handleOpenSnackbar={handleOpenSnackbar}
            />

        </div>
    );
}